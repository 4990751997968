import React, { useState, useEffect } from "react";
import NavigationBarComponent from "components/NavigationBarComponent";
import { setupSignalRConnection } from 'helpers/signalRhelper';

const NavigationBarContainer = () => {

    const [userBalance, setUserBalance] = useState(0);

    const receiveUserBalance = (response) => {
        setUserBalance(response.userBalance);
    };

    const userBalanceHub = process.env.REACT_APP_USER_BALANCE_HUB;
    const userBalanceConnectionMethod = 'UpdateUserBalanceAsync';

    useEffect(() => {
        setupSignalRConnection(userBalanceHub, userBalanceConnectionMethod, receiveUserBalance)
    }, [userBalanceHub, userBalanceConnectionMethod]);

    return (
        <NavigationBarComponent
            userBalance={userBalance}
        />
    );
};

export default NavigationBarContainer;
