import {
    JsonHubProtocol,
    HubConnectionState,
    HubConnectionBuilder
} from '@microsoft/signalr';

const signalRUrl = process.env.REACT_APP_SIGNALR_BASE;

const startSignalRConnection = async (connection, groupName) => {
    try {
        await connection.start();

        if (groupName)
            connection.invoke('JoinGroup', groupName);

        console.assert(connection.state === HubConnectionState.Connected);
        console.log('SignalR connection established');
    } catch (err) {
        console.assert(connection.state === HubConnectionState.Disconnected);
        console.error('SignalR Connection Error: ', err);
        setTimeout(() => startSignalRConnection(connection), 5000);
    }
};

export const setupSignalRConnection = (connectionHub, connectionMethod, responseReceiver, groupName) => {

    const connectionHubUrl = `${signalRUrl}${connectionHub}`;

    const connection = new HubConnectionBuilder()
        .withUrl(connectionHubUrl, { withCredentials: false })
        .withAutomaticReconnect()
        .withHubProtocol(new JsonHubProtocol())
        .build();

    connection.serverTimeoutInMilliseconds = 60000;

    connection.onclose(error => {
        console.assert(connection.state === HubConnectionState.Disconnected);
        console.log('Connection closed due to error. Try refreshing this page to restart the connection', error);
    });

    connection.onreconnecting(error => {
        console.assert(connection.state === HubConnectionState.Reconnecting);
        console.log('Connection lost due to error. Reconnecting.', error);
    });

    connection.onreconnected(connectionId => {
        console.assert(connection.state === HubConnectionState.Connected);
        console.log('Connection reestablished. Connected with connectionId', connectionId);
    });

    connection.on(connectionMethod, (response) => {
        if (response !== undefined && response !== null) {
            responseReceiver(response);
        }
    });

    startSignalRConnection(connection, groupName);

    return connection;
};
