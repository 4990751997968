import {
    mainBackgroundColor,
    mainTextColor,
    secondaryTextColor,
    contentBlocksHeaderColor,
    contentBlocksBackgroundColor,
    balanceValueColor,
} from 'constants/commonColors';
import { convertPxToRem } from 'helpers/convertPxToRem';

const themeParameters = {
    palette: {
        mode: 'dark',
        primary: {
            main: contentBlocksHeaderColor,
        },
        secondary: {
            main: contentBlocksBackgroundColor,
        },
        background: {
            default: mainBackgroundColor,
            paper: contentBlocksBackgroundColor,
        },
        text: {
            primary: mainTextColor,
            secondary: secondaryTextColor,
        },
    },
    typography: {
        fontFamily: '"Segoe UI"',
        frameCaption: {
            fontSize: convertPxToRem(14),
            fontWeight: '600',
            color: mainTextColor,
        },
        frameMainText: {
            fontSize: convertPxToRem(14),
            color: mainTextColor,
            lineHeight: convertPxToRem(24),
        },
        frameSecondaryText: {
            fontSize: convertPxToRem(13),
            color: secondaryTextColor,
        },
        bigCaption: {
            fontSize: convertPxToRem(16),
            color: mainTextColor,
        },
        smallCaption: {
            fontSize: convertPxToRem(12),
            color: secondaryTextColor,
        },
        buttonText: {
            fontSize: convertPxToRem(16),
            fontWeight: '700',
            color: mainTextColor,
        },
        balanceValueText: {
            fontSize: convertPxToRem(24),
            fontWeight: '700',
            color: balanceValueColor,
        },
        matchScore: {
            fontSize: convertPxToRem(30),
            fontWeight: '700',
            color: mainTextColor,
        },
        participantName: {
            fontSize: convertPxToRem(24),
            fontWeight: '400',
            color: mainTextColor,
        },
        matchTime: {
            fontSize: convertPxToRem(24),
            fontWeight: '700',
            color: mainTextColor,
        },
        leverageValueText: {
            fontSize: convertPxToRem(18),
            fontWeight: '700',
            color: mainTextColor,
        },
    },
    shape: {
        borderRadius: 4,
    },
};

export default themeParameters;
