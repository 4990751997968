export const headerBackgroundColor = '#131625';
export const mainBackgroundColor = '#252A44';
export const contentBlocksBackgroundColor = '#171B2B';
export const contentBlocksHeaderColor = '#003977';
export const activeElementColor = '#007BFF';
export const inactiveIconColor = '#8B8D95';
export const redChartColor = '#DC3545';
export const greenChartColor = '#28A745';
export const mainTextColor = '#FFFFFF';
export const secondaryTextColor = '#B0B8DB';
export const balanceValueColor = '#34D058';
export const betBuyButtonColor = '#0EC100';
export const betSellButtonColor = '#EB1A1A';
