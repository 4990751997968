import { makeStyles } from "@mui/styles";
import {
    contentBlocksBackgroundColor,
    activeElementColor,
    mainBackgroundColor,
    contentBlocksHeaderColor,
} from 'constants/commonColors';

const sportEventsStyles = makeStyles({
    sportEventsContainer: {
        backgroundColor: contentBlocksBackgroundColor,
        borderRadius: '0px 6px 6px 0px',
        height: '29.5%',
        '&:nth-of-type(2)': {
            marginTop: '8px',
        }
    },
    sportEventsContainerMaximized: {
        backgroundColor: contentBlocksBackgroundColor,
        borderRadius: '0px 6px 6px 0px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        bottom: '0',
        zIndex: '100',
        padding: 'inherit',
        left: '0',
    },
    sportEventItemContainer: {
        backgroundColor: mainBackgroundColor,
        width: '100%',
        height: '5.0686vh',
        marginTop: '1px',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    sportEventsContainerHeading: {
        backgroundColor: contentBlocksHeaderColor,
        color: '#AAAAAA',
        borderRadius: '0px 6px 0px 0px',
        width: '100%',
        height: '38px',
        padding: '7px 0px 7px 24px',
        display: 'inline-flex',
        justifyContent: 'space-between',
    },
    sportEventsList: {
        overflowY: 'auto',
        height: '79%',
        margin: '10px 4px 0px 24px',
        scrollbarColor: `${activeElementColor} ${mainBackgroundColor}`,
        scrollbarWidth: 'thin',
    },
    sportEventsListMaximized: {
        overflowY: 'auto',
        height: '94.5%',
        margin: '10px 4px 0px 24px',
        scrollbarColor: `${activeElementColor} ${mainBackgroundColor}`,
        scrollbarWidth: '1px',
    },
    stack: {
        justifyContent: 'space-around',
        height: '100%'
    },
});

export default sportEventsStyles;
