import { makeStyles } from "@mui/styles";
import { headerBackgroundColor } from 'constants/commonColors';

const navigationBarStyles = makeStyles({
    gridContainer: {
        backgroundColor: headerBackgroundColor,
        height: '7.4vh',
        padding: '0 3.36vh 0 3.36vh',
        marginBottom: '12px',
        '& .MuiGrid-container':
        {
            height: '100%',
        },
    },
});

export default navigationBarStyles;
