import { Fragment, useState, useEffect } from "react";
import SportEventsComponent from 'components/SportEventsComponent';
import SportEventApi from 'api/modules/sportEvent';
import { setupSignalRConnection } from 'helpers/signalRhelper';

const SportEventsContainer = props => {

    const {
        refSportEventId,
        eventGoals,
    } = props;

    const eventTimeHub = process.env.REACT_APP_EVENT_TIME_HUB;
    const gamePeriodConnectionMethod = 'UpdateGamePeriodAsync';

    const [currentSportEvents, setCurrentSportEvents] = useState([]);
    const [watchListSportEvents, setWatchListSportEvents] = useState([]);
    const [gamePeriod, setGamePeriod] = useState('');

    const receiveGamePeriod = (response) => {
        setGamePeriod(response.gamePeriod);
    };

    useEffect(() => { }, [gamePeriod]);

    useEffect(() => {
        const getResponse = async () => {
            const currentSportEventsResponse = await SportEventApi.getCurrentSportEvents();
            setCurrentSportEvents(currentSportEventsResponse);
            setWatchListSportEvents(currentSportEventsResponse);
        };
        getResponse();
    }, []);

    useEffect(() => {
        setupSignalRConnection(eventTimeHub, gamePeriodConnectionMethod, receiveGamePeriod);
    }, [eventTimeHub, gamePeriodConnectionMethod]);

    return (
        <Fragment>
            <SportEventsComponent
                headerText='CURRENT GAMES'
                sportEventsArray={currentSportEvents}
                gamePeriod={gamePeriod}
                isInWatchList={false}
                refSportEventId={refSportEventId}
                eventGoals={eventGoals}
            />
            <SportEventsComponent
                headerText='WATCH LIST'
                sportEventsArray={watchListSportEvents}
                gamePeriod={gamePeriod}
                isInWatchList={true}
                refSportEventId={refSportEventId}
                eventGoals={eventGoals}
            />
        </Fragment>
    );
};

export default SportEventsContainer;
