import { useState, useEffect } from 'react';
import {
    ToggleButton,
    ToggleButtonGroup,
    Card,
    CardMedia,
} from "@mui/material";
import buttonStyles from 'styles/ButtonStyles';

const TeamIconButtons = props => {

    const { refSelectedParticipantId, participantsInfo } = props;

    const firstParticipantId = participantsInfo[0] !== undefined ? participantsInfo[0].participantId : 0;
    const firstParticipantLogoPath = participantsInfo[0] !== undefined ? participantsInfo[0].logoPath : '';
    const secondParticipantId = participantsInfo[1] !== undefined ? participantsInfo[1].participantId : 0;
    const secondParticipantLogoPath = participantsInfo[1] !== undefined ? participantsInfo[1].logoPath : '';

    const [selectedParticipantId, setSelectedParticipantId] = useState(0);

    const { teamIconButton, teamIconButtonsGroup, teamIconCard } = buttonStyles();

    useEffect(() => {
        if (participantsInfo.length !== 0)
            setSelectedParticipantId(participantsInfo[0].participantId);
    }, [participantsInfo]);

    const handleSelectedTeamChange = (event, newSelectedParticipantId) => {
        if (newSelectedParticipantId === undefined || newSelectedParticipantId === null || newSelectedParticipantId === selectedParticipantId) {
            event.preventDefault();
        } else {
            refSelectedParticipantId.current = newSelectedParticipantId;
            setSelectedParticipantId(newSelectedParticipantId);
        }
    };

    const selectedParticipantControl = {
        value: selectedParticipantId,
        onChange: handleSelectedTeamChange,
        exclusive: true,
    };

    return (
        <ToggleButtonGroup {...selectedParticipantControl} className={teamIconButtonsGroup}>
            <ToggleButton
                value={firstParticipantId}
                className={teamIconButton}
            >
                <Card className={teamIconCard}>
                    <CardMedia
                        component="img"
                        image={firstParticipantLogoPath}
                        alt={`$first team logo`}
                    />
                </Card>
            </ToggleButton>
            <ToggleButton
                value={secondParticipantId}
                className={teamIconButton}
            >
                <Card className={teamIconCard}>
                    <CardMedia
                        component="img"
                        image={secondParticipantLogoPath}
                        alt={`secondParticipantLogoPath team logo`}
                    />
                </Card>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default TeamIconButtons;
