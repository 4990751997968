import navBarIcon from 'images/navBarIcon.svg';
import arrowDownIcon from 'images/arrowDownIcon.svg';
import avatarIcon from 'images/avatarIcon.svg';
import starIcon from 'images/starIcon.svg';
import symbolIcon from 'images/symbolIcon.svg';
import barcelonaIcon from 'images/barcelonaIcon.svg';
import realMadridIcon from 'images/realMadridIcon.svg';
import subtractionIcon from 'images/subtractIcon.svg';
import maximizeIcon from 'images/maximizeIcon.svg';
import openArrow from 'images/openArrow.svg';
import closeArrow from 'images/closeArrow.svg';
import leftArrow from 'images/leftArrow.svg';
import rightArrow from 'images/rightArrow.svg';
import seriesAIcon from 'images/seriesAIcon.svg';
import liveEventIcon from 'images/liveEventIcon.svg';
import calendarIcon from 'images/calendarIcon.svg';
import searchIcon from 'images/searchIcon.svg';
import chartBackground from 'images/chartBackground.svg';

export const NavBarIcon = () => { return < img src={navBarIcon} alt="DevoSportsIcon" style={{ marginLeft: '7px' }} />; };
export const ArrowDownIcon = () => { return <img src={arrowDownIcon} alt="ArrowDownIcon" style={{ marginLeft: '7px' }} />; };
export const AvatarIcon = () => { return <img src={avatarIcon} alt="Avatar Icon" style={{ marginLeft: '20px' }} />; };
export const StarIcon = () => { return <img src={starIcon} alt="Star Icon" style={{ marginLeft: '8px' }} />; };
export const SymbolIcon = () => { return <img src={symbolIcon} alt="Symbol Icon" style={{ marginLeft: '8px' }} />; };
export const RealMadridIcon = () => { return <img src={realMadridIcon} alt="Real Madrid Icon" />; };
export const BarcelonaIcon = () => { return <img src={barcelonaIcon} alt="Barcelona Icon" />; };
export const SubtractionIcon = () => { return <img src={subtractionIcon} alt="Subtraction Icon" />; };
export const MaximizeIcon = () => { return <img src={maximizeIcon} alt="Subtraction Icon" />; };
export const OpenArrow = () => { return <img src={openArrow} alt="Open Arrow Icon" />; };
export const CloseArrow = () => { return <img src={closeArrow} alt="Close Arrow Icon" />; };
export const LeftArrow = () => { return <img src={leftArrow} alt="Left Arrow Icon" />; };
export const RightArrow = () => { return <img src={rightArrow} alt="Close Arrow Icon" />; };
export const SeriesAIcon = () => { return <img src={seriesAIcon} alt="Series A Icon" />; };
export const LiveEventIcon = () => { return <img src={liveEventIcon} alt="Live Event Icon" />; };
export const CalendarIcon = () => { return <img src={calendarIcon} alt="Calendar Icon" />; };
export const SearchIcon = () => { return <img src={searchIcon} alt="Search Icon" />; };
export const ChartBackground = () => { return <img src={chartBackground} alt="Chart Background" style={{ position: 'absolute', alignSelf: 'center' }} />; };
