import { makeStyles } from "@mui/styles";
import {
    contentBlocksBackgroundColor,
    contentBlocksHeaderColor
} from 'constants/commonColors';

const candlestickChartStyles = makeStyles({
    chartContainer: {
        position: 'relative',
    },
    candlestickChartContainer: {
        backgroundColor: contentBlocksBackgroundColor,
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)',
        borderRadius: '10px',
        height: '86vh',
        width: '100%',
        display: 'inline-grid',
        justifyItems: 'center',
        '& > img': {
            width: 'inherit',
            padding: '0 4.63%',
        },
    },
    candlestickChartContainerHeading: {
        width: '100%',
        height: '62px',
        backgroundColor: contentBlocksHeaderColor,
        borderRadius: '10px 10px 0 0',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '6px 22px',
    },
    mainMatchInfoContainer: {
        display: 'inline-flex',
        width: 'auto',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& > *': {
            margin: '0 12px',
        },
    },
    volumeChartContainer: {
        backgroundColor: contentBlocksBackgroundColor,
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)',
        borderRadius: '10px',
        height: '11.35vh',
        marginTop: '20px',
        width: 'inherit',
    },
    candleStickChart: {
        width: 'inherit',
        height: '79vh',
    }
});

export default candlestickChartStyles;
