import { memo } from "react";
import DealHistoryComponent from "components/DealHistoryComponent";

const DealHistoryContainer = props => {

    const { deals } = props;

    return (
        <DealHistoryComponent deals={deals} />
    );
};

const dealPropsAreEqual = (prevProps, nextProps) => {
    return prevProps.deals.length === nextProps.deals.length;
};

export default memo(DealHistoryContainer, dealPropsAreEqual);
