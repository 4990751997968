import { makeStyles } from "@mui/styles";
import {
    contentBlocksBackgroundColor,
    activeElementColor,
    mainBackgroundColor,
    secondaryTextColor,
    contentBlocksHeaderColor,
} from 'constants/commonColors';

const openDealsStyles = makeStyles({
    openDealsContainer: {
        backgroundColor: contentBlocksBackgroundColor,
        borderRadius: '6px 0px 0px 0px',
        margin: '8px 400px 0 0',
        width: '100%',
        height: '52.7%',
    },
    openDealsContainerMaximized: {
        backgroundColor: contentBlocksBackgroundColor,
        borderRadius: '6px 0px 0px 0px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        bottom: 0,
        zIndex: '100',
    },
    openDealsContainerHeading: {
        backgroundColor: contentBlocksHeaderColor,
        padding: '7px 0px 7px 24px',
        borderRadius: '6px 0px 0px 0px',
        width: '100%',
        height: '38px',
        display: 'inline-flex',
        justifyContent: 'space-between',
    },
    openDealsList: {
        overflowY: 'auto',
        margin: '10px 4px 0px 12px',
        scrollbarColor: `${activeElementColor} ${mainBackgroundColor}`,
        scrollbarWidth: 'thin',
        height: '36.4vh',
    },
    openDealsListMaximized: {
        overflowY: 'auto',
        height: 'auto',
        margin: '10px 4px 0px 24px',
        scrollbarColor: `${activeElementColor} ${mainBackgroundColor}`,
        scrollbarWidth: 'thin',
    },
    openDealsListHeading: {
        width: 'auto',
        margin: '8px 12px 5px 12px',
        padding: '7px 0',
        borderBottom: `1px solid ${secondaryTextColor}`,
    },
    openDealsItemContainer: {
        backgroundColor: mainBackgroundColor,
        width: 'inherit',
        height: '4.3vh',
        marginTop: '1px',
        marginRight: '0',
    },
    openDealParticipantLogo: {
        width: '22.88px',
        height: '32px',
        backgroundColor: 'unset',
        transition: 'unset',
        borderRadius: 'unset',
        boxShadow: 'unset',
        backgroundImage: 'unset',
        '& img': {
            objectFit: 'contain',
            height: 'inherit',
        },
    },
});

export default openDealsStyles;
