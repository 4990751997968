import React, { useEffect, useState, Fragment } from 'react';
import TradingPageComponent from 'components/TradingPageComponent';
import TradingApi from 'api/modules/trading';
import SportEventApi from 'api/modules/sportEvent';
import { setupSignalRConnection } from 'helpers/signalRhelper';
import 'styles/css/scrollbar.css';
import CircularProgress from '@mui/material/CircularProgress';

const TradingPageContainer = props => {
    const { refSportEventId, refSelectedParticipantId, refCurrentUnitPrice } = props;

    const sportEventId = refSportEventId.current;

    const userId = 0; // Added only for alpha version without any user management

    const [participantsInfo, setParticipantsInfo] = useState([]);
    const [openedDeals, setOpenedDeals] = useState([]);
    const [closedDeals, setClosedDeals] = useState([]);
    const [eventTime, setEventTime] = useState('');
    const [eventGoals, setEventGoals] = useState([]);
    const [selectedDealId, setSelectedDealId] = useState();

    const dealsHub = process.env.REACT_APP_DEALS_HUB;
    const openedDealsConnectionMethod = 'UpdateOpenedDealsAsync';
    const closedDealsConnectionMethod = 'UpdateClosedDealsAsync';
    const eventGoalsHub = process.env.REACT_APP_EVENT_GOALS_HUB;
    const eventGoalsConnectionMethod = 'GetEventGoalsAsync';
    const [loading, setLoading] = useState(true);

    const receiveOpenedDeals = response => {
        setOpenedDeals(response.deals);
    };

    const receiveClosedDeals = response => {
        setClosedDeals(response.deals);
    };

    const receiveEventGoals = response => {
        setEventGoals(response);
    };

    const openDeal = async newDeal => {
        await TradingApi.openDeal(newDeal);
    };

    const closeDeal = async () => {
        if (selectedDealId) {
            const selectedDeal = openedDeals.find(deal => {
                if (deal.id === selectedDealId)
                    return deal;
                else
                    return null;
            });

            if (selectedDeal) {
                selectedDeal.closeTime = eventTime;
                selectedDeal.isClosed = true;

                await TradingApi.closeDeal(selectedDeal);
            }
        }
    };

    const getParticipantsInfo = async () => {
        const participantsInfoResponse = await SportEventApi.getParticipantsInfo(sportEventId);
        refSelectedParticipantId.current = participantsInfoResponse[0].participantId;
        setParticipantsInfo(participantsInfoResponse);
    };

    const getUserDeals = async () => {
        const userDeals = await TradingApi.getUserDeals(userId);
        setOpenedDeals(userDeals.filter(d => d.isClosed === false));
        setClosedDeals(userDeals.filter(d => d.isClosed === true));
    };

    useEffect(() => {
        setupSignalRConnection(eventGoalsHub, eventGoalsConnectionMethod, receiveEventGoals);
    }, [eventGoalsHub, eventGoalsConnectionMethod]);

    useEffect(() => {
        setupSignalRConnection(dealsHub, openedDealsConnectionMethod, receiveOpenedDeals);
    }, [dealsHub, openedDealsConnectionMethod]);

    useEffect(() => {
        setupSignalRConnection(dealsHub, closedDealsConnectionMethod, receiveClosedDeals);
    }, [dealsHub, closedDealsConnectionMethod]);

    useEffect(() => {
        getParticipantsInfo().then(setTimeout(() => setLoading(false), 2500));
        getUserDeals();
    }, [sportEventId]);

    return (
        <Fragment>
            <TradingPageComponent
                refSportEventId={refSportEventId}
                refSelectedParticipantId={refSelectedParticipantId}
                refCurrentUnitPrice={refCurrentUnitPrice}
                participantsInfo={participantsInfo}
                openDeal={openDeal}
                closeDeal={closeDeal}
                openedDeals={openedDeals}
                closedDeals={closedDeals}
                eventTime={eventTime}
                eventGoals={eventGoals}
                setEventTime={setEventTime}
                selectedDealId={selectedDealId}
                setSelectedDealId={setSelectedDealId}
                loading={loading}
            />
            {loading === false ? (
                ''
            ) : (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh',
                        }}
                    >
                        <CircularProgress size={200} style={{ color: 'white' }} />
                    </div>
                )}
        </Fragment>
    );
};

export default TradingPageContainer;
