const candlestickChartOptions = {
    candle: {
        bar: {
            upColor: '#28A745',
            downColor: '#DC3545',
            noChangeColor: '#888888'
        },
        tooltip: {
            showRule: 'always',
            showType: 'standard',
            labels: ['Time ', 'Open ', 'Close ', 'High ', 'Low ', 'Volume '],
        },
    },
    technicalIndicator: {
        bar: {
            upColor: '#28A745',
            downColor: '#DC3545',
            noChangeColor: '#888888'
        },
    }
};

export default candlestickChartOptions;
