import React from "react";
import OpenDealsComponent from 'components/OpenDealsComponent';

const OpenDealsContainer = props => {

    const { deals, selectedDealId, setSelectedDealId } = props;

    return (
        <OpenDealsComponent
            deals={deals}
            selectedDealId={selectedDealId}
            setSelectedDealId={setSelectedDealId}
        />
    );
};

export default OpenDealsContainer;
