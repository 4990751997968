import { useState, memo } from "react";
import {
    Typography,
    IconButton,
    ToggleButtonGroup,
    ToggleButton,
} from "@mui/material";
import sportEventsCalendarStyles from 'styles/SportEventsCalendarStyles';
import buttonStyles from 'styles/ButtonStyles';
import {
    SubtractionIcon,
    MaximizeIcon,
    OpenArrow,
    CloseArrow,
    LeftArrow,
    RightArrow,
    SeriesAIcon,
    CalendarIcon,
    LiveEventIcon,
    SearchIcon,
} from 'images';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

const SportEventsCalendarComponent = () => {

    const {
        sportEventsCalendarContainer,
        sportEventsCalendarContainerMaximized,
        sportEventsCalendarContainerHeading,
        sportEventsCalendarList,
        sportEventsCalendarListMaximized,
        daySwitcher,
        calendarControlsContainer,
    } = sportEventsCalendarStyles();

    const { maximizeContainerButton,
        toggleButton,
        toggleButtonGroup,
        searchButton,
        calendarButton,
    } = buttonStyles();

    const [isMaximized, setChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const [alignment, setAlignment] = useState('all');
    const [dayValue, setDay] = useState('TODAY');

    const handleMaximizeClick = () => {
        setChecked((prev) => !prev);
    };

    const handleFilterChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const handleListItemClick = () => {
        setOpen(!open);
    };

    return (
        <div component="main" className={isMaximized ? sportEventsCalendarContainerMaximized : sportEventsCalendarContainer}>
            <div className={sportEventsCalendarContainerHeading}>
                <Typography variant='frameCaption'>
                    CALENDAR
                </Typography>
                <IconButton onClick={handleMaximizeClick} className={maximizeContainerButton}>
                    {isMaximized ? <SubtractionIcon /> : <MaximizeIcon />}
                </IconButton>
            </div>
            <div className={calendarControlsContainer}>
                <div className={daySwitcher}>
                    <IconButton>
                        <LeftArrow />
                    </IconButton>
                    <Typography variant='frameMainText'>
                        {dayValue}
                    </Typography>
                    <IconButton>
                        <RightArrow />
                    </IconButton>
                </div>
                <IconButton className={calendarButton}>
                    <CalendarIcon />
                </IconButton>
                <IconButton className={searchButton}>
                    <SearchIcon />
                </IconButton>
            </div>
            <ToggleButtonGroup
                exclusive
                color="primary"
                value={alignment}
                onChange={handleFilterChange}
                className={toggleButtonGroup}
            >
                <ToggleButton value="all" className={toggleButton}>
                    <Typography variant='frameMainText'>All</Typography>
                </ToggleButton>
                <ToggleButton value="live" className={toggleButton}>
                    <Typography variant='frameMainText'>Live</Typography>
                </ToggleButton>
                <ToggleButton value="finished" className={toggleButton}>
                    <Typography variant='frameMainText'>Finished</Typography>
                </ToggleButton>
                <ToggleButton value="scheduled" className={toggleButton}>
                    <Typography variant='frameMainText'>Scheduled</Typography>
                </ToggleButton>
            </ToggleButtonGroup>
            <div className={isMaximized ? sportEventsCalendarListMaximized : sportEventsCalendarList}>
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <ListItemButton>
                        <ListItemIcon>
                            <SeriesAIcon />
                        </ListItemIcon>
                        <ListItemText primary="Premier League" />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemText primary="Series A" />
                    </ListItemButton>
                    <ListItemButton onClick={handleListItemClick}>
                        <ListItemText primary="Ukraine Premier League" />
                        {open ? <CloseArrow /> : <OpenArrow />}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText primary="Starred" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleListItemClick}>
                        <ListItemText primary="Ukraine Premier League" />
                        {open ? <CloseArrow /> : <OpenArrow />}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText primary="Starred" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                </List>
            </div>
        </div>
    );
};

export default memo(SportEventsCalendarComponent);
