import React, { Fragment } from "react";
import SportEventsCalendarComponent from "components/SportEventsCalendarComponent";

const SportEventsCalendarContainer = () => {

    return (
        <Fragment>
            <SportEventsCalendarComponent />
        </Fragment>
    );
};

export default SportEventsCalendarContainer;
