import { useState } from "react";
import {
    Typography,
    IconButton,
    Grid,
} from "@mui/material";
import dealHistoryStyles from 'styles/DealHistoryStyles';
import DealHistoryItem from '../DealHistoryItem';
import {
    SubtractionIcon,
    MaximizeIcon,
} from 'images';
import buttonStyles from 'styles/ButtonStyles';

const DealHistoryComponent = props => {

    const { deals } = props;

    const [isMaximized, setChecked] = useState(false);

    const handleClick = () => {
        setChecked((prev) => !prev);
    };

    const {
        dealsHistoryContainer,
        dealsHistoryContainerMaximized,
        dealsHistoryContainerHeading,
        dealsHistoryList,
        dealsHistoryListMaximized,
        dealsHistoryListHeading,
        dealsHistoryListHeadingMaximized,
    } = dealHistoryStyles();

    const { maximizeContainerButton } = buttonStyles();

    const gridItemStyle = { display: 'inline-flex', alignSelf: 'center', justifyContent: 'center' };

    return (
        <div component="main" className={isMaximized ? dealsHistoryContainerMaximized : dealsHistoryContainer}>
            <div className={dealsHistoryContainerHeading}>
                <Typography variant='frameCaption'>
                    HISTORY
                </Typography>
                <IconButton onClick={handleClick} className={maximizeContainerButton}>
                    {isMaximized ? <SubtractionIcon /> : <MaximizeIcon />}
                </IconButton>
            </div>
            <div className={isMaximized ? dealsHistoryListHeadingMaximized : dealsHistoryListHeading}>
                <Grid container sx={{ width: 'unset' }}>
                    <Grid item xs={1.714} sx={gridItemStyle}>
                        <Typography variant='frameSecondaryText'>Team</Typography>
                    </Grid>
                    <Grid item xs={1.714} sx={gridItemStyle}>
                        <Typography variant='frameSecondaryText'>Unit</Typography>
                    </Grid>
                    <Grid item xs={1.714} sx={gridItemStyle}>
                        <Typography variant='frameSecondaryText'>Op. price</Typography>
                    </Grid>
                    <Grid item xs={1.714} sx={gridItemStyle}>
                        <Typography variant='frameSecondaryText'>Cl. price</Typography>
                    </Grid>
                    <Grid item xs={1.714} sx={gridItemStyle}>
                        <Typography variant='frameSecondaryText'>Time Op.</Typography>
                    </Grid>
                    <Grid item xs={1.714} sx={gridItemStyle}>
                        <Typography variant='frameSecondaryText'>Time Cl.</Typography>
                    </Grid>
                    <Grid item xs={1.714} sx={gridItemStyle}>
                        <Typography variant='frameSecondaryText'>P/L</Typography>
                    </Grid>
                </Grid>
            </div>
            <div className={isMaximized ? dealsHistoryListMaximized : dealsHistoryList}>
                {deals ? deals
                    .map(deal => {
                        return <DealHistoryItem key={deal.id} closedDeal={deal} />;
                    })
                    : ''}
            </div>
        </div >
    );
};

export default DealHistoryComponent;
