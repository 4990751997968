import React, { useState } from 'react';
import TradingComponent from 'components/TradingComponent';

const TradingContainer = props => {

    const {
        refSportEventId,
        refSelectedParticipantId,
        refCurrentUnitPrice,
        participantsInfo,
        openDeal,
        closeDeal,
        eventTime
    } = props;

    const [unitsAmountErrorText, setUnitsAmountErrorText] = useState('');
    const [takeProfitErrorText, setTakeProfitErrorText] = useState('');
    const [stopLossErrorText, setStopLossErrorText] = useState('');

    const handleBuyButtonClick = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const newDeal = {
            id: 0,
            userId: 0,
            sportEventId: refSportEventId.current,
            participantId: refSelectedParticipantId.current,
            participantAcronym: '',
            participantLogoPath: participantsInfo[0].logoPath,
            isClosed: false,
            units: getValueFromTextField(formData, 'unitsAmount'),
            takeProfit: getValueFromTextField(formData, 'takeProfit'),
            stopLoss: getValueFromTextField(formData, 'stopLoss'),
            openPrice: refCurrentUnitPrice.current,
            openTime: eventTime,
            closeTime: '',
            profitLoss: 0,
        };

        openDeal(newDeal);
        event.currentTarget.reset();
    };

    const handleSellButtonClick = () => {
        closeDeal();
    };

    const handleBlur = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const unitsAmount = formData.get('unitsAmount');
        const takeProfit = formData.get('takeProfit');
        const stopLoss = formData.get('stopLoss');

        if (isNaN(unitsAmount))
            setUnitsAmountErrorText('Units Amount value error');
        else if (unitsAmount === '')
            setUnitsAmountErrorText('');
        else
            setUnitsAmountErrorText('');

        if (isNaN(takeProfit))
            setTakeProfitErrorText('Take Profit value error');
        else if (takeProfit === '')
            setTakeProfitErrorText('');
        else if (+takeProfit <= refCurrentUnitPrice.current)
            setTakeProfitErrorText('Take Profit must be bigger than current unit price');
        else
            setTakeProfitErrorText('');

        if (isNaN(stopLoss))
            setStopLossErrorText('Stop Loss value error');
        else if (stopLoss === '')
            setStopLossErrorText('');
        else if (+stopLoss >= +takeProfit)
            setStopLossErrorText('Stop Loss must be less than Take Profit');
        else if (+stopLoss >= refCurrentUnitPrice.current)
            setStopLossErrorText('Stop Loss must be less than current unit price');
        else
            setStopLossErrorText('');
    };

    const getValueFromTextField = (formData, fieldName) => {
        if (formData.get(fieldName) === '')
            return null;

        return +formData.get(fieldName);
    }

    return (
        <TradingComponent
            refSelectedParticipantId={refSelectedParticipantId}
            participantsInfo={participantsInfo}
            openDeal={openDeal}
            closeDeal={closeDeal}
            handleBuyButtonClick={handleBuyButtonClick}
            handleSellButtonClick={handleSellButtonClick}
            handleBlur={handleBlur}
            unitsAmountErrorText={unitsAmountErrorText}
            takeProfitErrorText={takeProfitErrorText}
            stopLossErrorText={stopLossErrorText}
        />
    );
};

export default TradingContainer;
