import { Typography, Card, CardMedia } from '@mui/material';
import participantInfoStyles from 'styles/ParticipantInfoStyles';

const ParticipantInfo = props => {
    const { participantLogoPath, participantAcronym, flexDirection } = props;
    const { participantInfoContainer, card } = participantInfoStyles();

    return (
        <div className={participantInfoContainer} style={{ flexDirection: flexDirection }}>
            <Card className={card}>
                <CardMedia component="img" image={participantLogoPath} alt={`${participantAcronym} team logo`} />
            </Card>
            <Typography variant="participantName">{participantAcronym}</Typography>
        </div>
    );
};

export default ParticipantInfo;
