import apiClient from '../apiClient';

const controllerBase = '/SportEvent';

export default class SportEventApi {
    static async getParticipantAcronyms(sportEventId) {

        const response = await apiClient.get(`${controllerBase}/GetParticipantAcronyms`, { params: { sportEventId } });
        return response.data;
    }

    static async getParticipantsInfo(sportEventId) {

        const response = await apiClient.get(`${controllerBase}/GetParticipantsInfo`, { params: { sportEventId } });
        return response.data.participantsInfo;
    }

    static async getLeverage(sportEventId) {

        const response = await apiClient.get(`${controllerBase}/GetLeverage`, { params: { sportEventId } });
        return response.data;
    }

    static async getCurrentSportEvents() {

        const response = await apiClient.get(`${controllerBase}/GetCurrentSportEvents`);
        return response.data.currentSportEvents;
    }
}
