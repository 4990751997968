import React, { Fragment } from "react";
import themeParameters from 'styles/MaterialUITheme';
import CssBaseline from '@mui/material/CssBaseline';
import {
    ThemeProvider,
    createTheme,
    StyledEngineProvider
} from '@mui/material/styles';
import './presentation.css';
import slide_0 from './slides/slide-0.png';
import slide_1 from './slides/slide-1.png';
import slide_2 from './slides/slide-2.png';
import slide_3 from './slides/slide-3.png';

const Presentation = () => {
    const theme = createTheme(themeParameters);

    return (
        <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
                <CssBaseline />
                <Fragment>
                    <div id="slide-container">
                        <div class="slide"><img src={slide_0} alt="slide 0" /></div>
                        <div class="slide"><img src={slide_1} alt="slide 1" /></div>
                        <div class="slide"><img src={slide_2} alt="slide 2" /></div>
                        <div class="slide"><img src={slide_3} alt="slide 3" /></div>
                    </div>
                </Fragment>
            </StyledEngineProvider>
        </ThemeProvider>
    );
};

export default Presentation;
