import apiClient from '../apiClient';

const controllerBase = '/Trading';

export default class TradingApi {
    static async openDeal(dealDto) {

        return apiClient.post(`${controllerBase}/OpenDeal`, dealDto);
    }

    static async closeDeal(deal) {

        return apiClient.patch(`${controllerBase}/CloseDeal`, deal);
    }

    static async getUserDeals(userId) {

        const response = await apiClient.get(`${controllerBase}/GetUserDeals`, { params: { userId } });

        return response.data.userDeals;
    }

    static async resetUserBalance() {
        await apiClient.patch(`${controllerBase}/ResetUserBalance`);
    }
}
