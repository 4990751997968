import { init, dispose } from 'klinecharts';
import { useState, useEffect } from 'react';
import candlestickChartStyles from 'styles/CandlestickChartStyles';
import { Typography } from '@mui/material';
import { ChartBackground } from 'images';
import candleStickChartOptions from './KlineChartOptions/candlestickChartOptions';
import ParticipantInfo from './ParticipantInfo';

const CandlestickChartComponent = props => {
    const {
        japaneseCandles,
        setJapaneseCandles,
        participantAcronyms,
        participantsInfo,
        leverage,
        eventTime,
        eventGoals,
        sportEventId,
        loading,
    } = props;

    const [candleStick, setCandleStick] = useState(null);

    const initializeChart = () => {
        const newCandleStick = init('candleStickChart');
        newCandleStick.setStyleOptions(candleStickChartOptions);
        newCandleStick.createTechnicalIndicator('VOL', false);
        return newCandleStick;
    };

    useEffect(() => {
        if (loading === false && japaneseCandles.length <= 1 && candleStick === null) {
            setCandleStick(initializeChart());
        }
    }, [loading, japaneseCandles.length, candleStick]);

    useEffect(() => {
        if (candleStick)
            candleStick.applyNewData(japaneseCandles);
    }, [candleStick, japaneseCandles]);

    useEffect(() => {
        if (candleStick) {
            candleStick.clearData();
            dispose('candleStickChart');
            setJapaneseCandles([]);
            setCandleStick(initializeChart());
        }
    }, [sportEventId]);

    const firstParticipantAcronym = participantAcronyms[0] !== undefined ? participantAcronyms[0].acronym : '';
    const secondParticipantAcronym = participantAcronyms[1] !== undefined ? participantAcronyms[1].acronym : '';
    const firstParticipantLogoPath = participantsInfo[0] !== undefined ? participantsInfo[0].logoPath : '';
    const secondParticipantLogoPath = participantsInfo[1] !== undefined ? participantsInfo[1].logoPath : '';

    const [firstParticipantScore, setFirstParticipantScore] = useState(0);
    const [secondParticipantScore, setSecondParticipantScore] = useState(0);

    if (eventGoals) {
        let eventGoalsValues = Object.values(eventGoals);

        if (firstParticipantScore !== eventGoalsValues[0])
            setFirstParticipantScore(eventGoalsValues[0]);

        if (secondParticipantScore !== eventGoalsValues[1])
            setSecondParticipantScore(eventGoalsValues[1]);
    }

    const leverageText = leverage !== 0 ? `1:${leverage}` : '';
    const timerTime = eventTime !== '' ? eventTime : '00:00';

    const {
        candlestickChartContainer,
        candlestickChartContainerHeading,
        mainMatchInfoContainer,
        chartContainer,
        candleStickChart,
    } = candlestickChartStyles();

    return (
        <div className={chartContainer}>
            <div className={candlestickChartContainer}>
                <ChartBackground />
                <div className={candlestickChartContainerHeading}>
                    <Typography variant="matchTime">{timerTime}</Typography>
                    <div className={mainMatchInfoContainer}>
                        <ParticipantInfo
                            participantLogoPath={firstParticipantLogoPath}
                            participantAcronym={firstParticipantAcronym}
                        />
                        <Typography variant="matchScore">
                            {firstParticipantScore}-{secondParticipantScore}
                        </Typography>
                        <ParticipantInfo
                            participantLogoPath={secondParticipantLogoPath}
                            participantAcronym={secondParticipantAcronym}
                            flexDirection="row-reverse"
                        />
                    </div>
                    <Typography variant="leverageValueText">{leverageText}</Typography>
                </div>
                <div id="candleStickChart" className={candleStickChart} />
            </div>
        </div>
    );
};

export default CandlestickChartComponent;
