import { useState, memo } from "react";
import {
    Typography,
    IconButton,
    ToggleButtonGroup,
    ToggleButton,
} from "@mui/material";
import sportEventsStyles from 'styles/SportEventsStyles';
import buttonStyles from 'styles/ButtonStyles';
import SportEventItem from '../SportEventItem';
import {
    SubtractionIcon,
    MaximizeIcon,
} from 'images';
import { splitSportEventName } from 'helpers/sportEventNameSplitter';

const SportEventsComponent = props => {

    const {
        sportEventsContainer,
        sportEventsContainerMaximized,
        sportEventsContainerHeading,
        sportEventsList,
        sportEventsListMaximized,
    } = sportEventsStyles();

    const {
        maximizeContainerButton,
        sportEventItemButtonGroup,
        sportEventItemButton,
    } = buttonStyles();

    const {
        headerText,
        sportEventsArray,
        gamePeriod,
        isInWatchList,
        refSportEventId,
        eventGoals,
    } = props;

    const [isMaximized, setChecked] = useState(false);
    const [selectedSportEvent, setSelectedSportEvent] = useState(refSportEventId.current);

    const handleClick = e => {
        setChecked((prev) => !prev);
    };

    const handleSportEventChange = (event, newSelectedSportEvent) => {
        if (newSelectedSportEvent === undefined || newSelectedSportEvent === null || newSelectedSportEvent === selectedSportEvent) {
            event.preventDefault();
        } else {
            setSelectedSportEvent(newSelectedSportEvent);
            refSportEventId.current = newSelectedSportEvent;
        }
    };

    return (
        <div component="main" className={isMaximized ? sportEventsContainerMaximized : sportEventsContainer}>
            <div className={sportEventsContainerHeading}>
                <Typography variant='frameCaption'>
                    {headerText}
                </Typography>
                <IconButton onClick={handleClick} className={maximizeContainerButton}>
                    {isMaximized ? <SubtractionIcon /> : <MaximizeIcon />}
                </IconButton>
            </div>
            <div className={isMaximized ? sportEventsListMaximized : sportEventsList}>
                <ToggleButtonGroup
                    exclusive
                    color="primary"
                    orientation="vertical"
                    name="SportEventItemButtonGroup"
                    value={selectedSportEvent}
                    onChange={handleSportEventChange}
                    className={sportEventItemButtonGroup}
                >
                    {sportEventsArray !== undefined ?
                        sportEventsArray.map(
                            sportEvent => {
                                const key = sportEvent.id;
                                const sportEventParticipants = splitSportEventName(sportEvent.name);
                                return (
                                    <ToggleButton name={key} key={key} value={key} className={sportEventItemButton}>
                                        <SportEventItem
                                            key={key}
                                            sportEventParticipants={sportEventParticipants}
                                            gamePeriod={gamePeriod}
                                            isInWatchList={isInWatchList}
                                            eventGoals={eventGoals[sportEvent.id]}
                                        />
                                    </ToggleButton>);
                            }
                        ) : ''}
                </ToggleButtonGroup>
            </div>
        </div>
    );
};

const sportEventPropsAreEqual = (prevProps, nextProps) => {
    const isHeaderTextChanged = prevProps.headerText === nextProps.headerText;
    const isSportEventsArrayChanged = prevProps.sportEventsArray.length === nextProps.sportEventsArray.length;
    const isGamePeriodChanged = prevProps.gamePeriod === nextProps.gamePeriod;
    const isInWatchListChanged = prevProps.isInWatchList === nextProps.isInWatchList;
    const isRefSportEventIdChanged = prevProps.refSportEventId.current === nextProps.refSportEventId.current;
    const isEventGoalsChanged = JSON.stringify(prevProps.eventGoals) === JSON.stringify(nextProps.eventGoals);

    return isHeaderTextChanged && isSportEventsArrayChanged && isGamePeriodChanged && isInWatchListChanged && isRefSportEventIdChanged && isEventGoalsChanged;
};

export default memo(SportEventsComponent, sportEventPropsAreEqual);
