import tradingStyles from 'styles/TradingStyles';
import {
    Typography,
    Grid,
    TextField,
    Box,
} from "@mui/material";
import { betBuyButtonColor, betSellButtonColor } from 'constants/commonColors';
import CandleTimeControlButtons from './CandleTimeControlButtons';
import TeamIconButtons from './TeamIconButtons';
import BetControlButton from './TradingContolButton';

const TradingComponent = props => {

    const {
        refSelectedParticipantId,
        handleBuyButtonClick,
        handleSellButtonClick,
        handleBlur,
        participantsInfo,
        unitsAmountErrorText,
        takeProfitErrorText,
        stopLossErrorText,
    } = props;

    const {
        tradingContainer,
        unitAmountTextField,
        priceTextField,
    } = tradingStyles();

    const candleTimeValues = [15, 30, 60];

    return (
        <div component="main" className={tradingContainer}>
            <Box>
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                        <CandleTimeControlButtons candleTimeValues={candleTimeValues} />
                    </Grid>
                    <Grid item xs={12}>
                        {participantsInfo ? <TeamIconButtons refSelectedParticipantId={refSelectedParticipantId} participantsInfo={participantsInfo} /> : ''}
                    </Grid>
                </Grid>
                <Box component="form" onSubmit={handleBuyButtonClick} onBlur={handleBlur} noValidate>
                    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="outlined-basic"
                                label="Units Amount"
                                name="unitsAmount"
                                variant="outlined"
                                size="small"
                                className={unitAmountTextField}
                                error={!!unitsAmountErrorText}
                                helperText={unitsAmountErrorText}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1.5}>
                                <Grid item xs={6}>
                                    <Typography variant="bigCaption">Take Profit</Typography>
                                    <TextField
                                        id="outlined-basic"
                                        label="Price"
                                        name="takeProfit"
                                        variant="outlined"
                                        size="small"
                                        className={priceTextField}
                                        error={!!takeProfitErrorText}
                                        helperText={takeProfitErrorText}
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="bigCaption">Stop Loss</Typography>
                                    <TextField
                                        id="outlined-basic"
                                        label="Price"
                                        name="stopLoss"
                                        variant="outlined"
                                        size="small"
                                        className={priceTextField}
                                        error={!!stopLossErrorText}
                                        helperText={stopLossErrorText}
                                        autoComplete="off"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1.5}>
                                <Grid item xs={6}>
                                    <BetControlButton text='BUY' backgroundColor={betBuyButtonColor} type="submit" />
                                </Grid>
                                <Grid item xs={6}>
                                    <BetControlButton text='SELL' backgroundColor={betSellButtonColor} handleSellButtonClick={handleSellButtonClick} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div >
    );
};

export default TradingComponent;
