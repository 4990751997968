import { makeStyles } from "@mui/styles";
import {
    contentBlocksBackgroundColor,
    activeElementColor,
    mainBackgroundColor,
    secondaryTextColor,
    contentBlocksHeaderColor,
} from 'constants/commonColors';

const dealHistoryStyles = makeStyles({
    dealsHistoryContainer: {
        backgroundColor: contentBlocksBackgroundColor,
        borderRadius: '10px 10px 0px 0px',
        height: '38px',
        position: 'relative',
    },
    dealsHistoryContainerMaximized: {
        backgroundColor: contentBlocksBackgroundColor,
        borderRadius: '10px 10px 0px 0px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        bottom: 0,
        zIndex: 2,
    },
    dealsHistoryContainerHeading: {
        backgroundColor: contentBlocksHeaderColor,
        padding: '7px 0px 7px 24px',
        borderRadius: '10px 10px 0px 0px',
        width: '100%',
        height: '38px',
        display: 'inline-flex',
        justifyContent: 'space-between',
    },
    dealsHistoryList: {
        overflowY: 'auto',
        display: 'none',
        margin: '10px 4px 0px 4px',
        scrollbarColor: `${activeElementColor} ${mainBackgroundColor}`,
        scrollbarWidth: 'thin',
    },
    dealsHistoryListMaximized: {
        overflowY: 'auto',
        height: '80.77vh',
        margin: '10px 4px 0px 4px',
        scrollbarColor: `${activeElementColor} ${mainBackgroundColor}`,
        scrollbarWidth: 'thin',
    },
    dealsHistoryListHeading: {
        display: 'none',
    },
    dealsHistoryListHeadingMaximized: {
        margin: '8px 8px 5px',
        padding: '7px 0',
        borderBottom: `1px solid ${secondaryTextColor}`,
    },
    dealsHistoryItemContainer: {
        backgroundColor: mainBackgroundColor,
        width: '100%',
        height: '44px',
        marginTop: '1px',
    },
});

export default dealHistoryStyles;
