import { useState } from "react";
import {
    Typography,
    IconButton,
    Grid,
    ToggleButtonGroup,
    ToggleButton
} from "@mui/material";
import openDealsStyles from 'styles/OpenDealsStyles';
import OpenDealItem from '../OpenDealItem';
import {
    SubtractionIcon,
    MaximizeIcon,
} from 'images';
import buttonStyles from 'styles/ButtonStyles';

const OpenDealsComponent = props => {

    const {
        deals,
        selectedDealId,
        setSelectedDealId
    } = props;

    const [isMaximized, setChecked] = useState(false);

    const handleClick = () => {
        setChecked((prev) => !prev);
    };

    const {
        openDealsContainer,
        openDealsContainerMaximized,
        openDealsContainerHeading,
        openDealsList,
        openDealsListMaximized,
        openDealsListHeading,
    } = openDealsStyles();

    const {
        dealItemButtonGroup,
        dealItemButton,
    } = buttonStyles();

    const { maximizeContainerButton } = buttonStyles();

    const handleDealSelectionChange = (event, newSelectedDealId) => {
        setSelectedDealId(newSelectedDealId);
    };

    const gridItemStyle = { display: 'inline-flex', alignSelf: 'center', justifyContent: 'center' };

    return (
        <div component="main" className={isMaximized ? openDealsContainerMaximized : openDealsContainer}>
            <div className={openDealsContainerHeading}>
                <Typography variant='frameCaption'>
                    OPEN DEALS
                </Typography>
                <IconButton onClick={handleClick} className={maximizeContainerButton}>
                    {isMaximized ? <SubtractionIcon /> : <MaximizeIcon />}
                </IconButton>
            </div>
            <div className={openDealsListHeading}>
                <Grid container sx={{ width: 'unset' }}>
                    <Grid item xs={2} sx={gridItemStyle}>
                        <Typography variant='frameSecondaryText'>Symbol</Typography>
                    </Grid>
                    <Grid item xs={2.5} sx={gridItemStyle}>
                        <Typography variant='frameSecondaryText'>Unit</Typography>
                    </Grid>
                    <Grid item xs={2.5} sx={gridItemStyle}>
                        <Typography variant='frameSecondaryText'>Price</Typography>
                    </Grid>
                    <Grid item xs={2.5} sx={gridItemStyle}>
                        <Typography variant='frameSecondaryText'>Time</Typography>
                    </Grid>
                    <Grid item xs={2.5} sx={gridItemStyle}>
                        <Typography variant='frameSecondaryText'>P/L</Typography>
                    </Grid>
                </Grid>
            </div>
            <div className={isMaximized ? openDealsListMaximized : openDealsList}>
                <ToggleButtonGroup
                    exclusive
                    color="primary"
                    orientation="vertical"
                    name="DealItemButtonGroup"
                    value={selectedDealId}
                    onChange={handleDealSelectionChange}
                    className={dealItemButtonGroup}
                >
                    {deals ? deals.map(deal => {
                        return (
                            <ToggleButton name={deal.id} key={deal.id} value={deal.id} className={dealItemButton}>
                                <OpenDealItem key={deal.id} openedDeal={deal} />
                            </ToggleButton>
                        );
                    }
                    ) : ''}

                </ToggleButtonGroup>
            </div>
        </div >
    );
};

export default OpenDealsComponent;
