import { makeStyles } from "@mui/styles";
import {
    contentBlocksBackgroundColor,
    mainBackgroundColor
} from 'constants/commonColors';

const tradingStyles = makeStyles({
    tradingContainer: {
        backgroundColor: contentBlocksBackgroundColor,
        borderRadius: '6px 0px 0px 0px',
        padding: '24px 14px',
    },
    unitAmountTextField: {
        borderRadius: '4px',
        backgroundColor: mainBackgroundColor,
        width: '100%',
        margin: '24px 0 12px 0',
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #FFFFFF',
        },
        '& .Mui-focused': {
            color: '#FFFFFF',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #FFFFFF',
        }
    },
    priceTextField: {
        borderRadius: '4px',
        backgroundColor: mainBackgroundColor,
        marginTop: '9px',
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #FFFFFF',
        },
        '& .Mui-focused': {
            color: '#FFFFFF',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #FFFFFF',
        }
    },
});

export default tradingStyles;
