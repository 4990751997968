import React, { Fragment } from "react";
import NavigationBarContainer from '../NavigationBarContainer';
import themeParameters from 'styles/MaterialUITheme';
import CssBaseline from '@mui/material/CssBaseline';
import {
    ThemeProvider,
    createTheme,
    StyledEngineProvider
} from '@mui/material/styles';

const WrapperContainer = props => {

    const theme = createTheme(themeParameters);
    return (
        <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
                <CssBaseline />
                <Fragment>
                    <NavigationBarContainer />
                    {props.children}
                </Fragment>
            </StyledEngineProvider>
        </ThemeProvider>
    );
};

export default WrapperContainer;
