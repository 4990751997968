import apiClient from '../apiClient';

const controllerBase = '/Candle';

export default class CandleInfoApi {
    static async setCandleConfig(candleInterval, sportEventId, startAllEvents,) {
        const requestData = {
            candleInterval: candleInterval,
            sportEventId: sportEventId,
            startAllEvents: startAllEvents,
        };

        return apiClient.post(`${controllerBase}/SetCandleConfig`, requestData);
    }

    static async getCandleHistory(sportEventId, participantId) {
        const response = await apiClient.get(`${controllerBase}/GetCandleHistory`, { params: { sportEventId, participantId } });
        return response.data.candleHistory;
    }

    static async deleteJob() {
        return apiClient.delete(`${controllerBase}/DeleteJob`);
    }
}
