import { Grid, Typography } from "@mui/material";
import dealHistoryStyles from 'styles/DealHistoryStyles';

const DealHistoryItem = props => {

    const {
        dealsHistoryItemContainer
    } = dealHistoryStyles();

    const {
        closedDeal
    } = props;

    const {
        participantAcronym,
        units,
        openPrice,
        closePrice,
        openTime,
        closeTime,
        profitLoss,
    } = closedDeal;

    const gridItemStyle = { display: 'inline-flex', alignSelf: 'center', justifyContent: 'center' };

    return (
        <Grid container className={dealsHistoryItemContainer} sx={{ width: 'unset' }}>
            <Grid item xs={1.714} sx={gridItemStyle}>
                <Typography variant='frameSecondaryText' >{participantAcronym}</Typography>
            </Grid>
            <Grid item xs={1.714} sx={gridItemStyle}>
                <Typography variant='frameSecondaryText' >{units}</Typography>
            </Grid>
            <Grid item xs={1.714} sx={gridItemStyle}>
                <Typography variant='frameSecondaryText'>{openPrice}</Typography>
            </Grid>
            <Grid item xs={1.714} sx={gridItemStyle}>
                <Typography variant='frameSecondaryText' >{closePrice}</Typography>
            </Grid>
            <Grid item xs={1.714} sx={gridItemStyle}>
                <Typography variant='frameSecondaryText' >{openTime}</Typography>
            </Grid>
            <Grid item xs={1.714} sx={gridItemStyle}>
                <Typography variant='frameSecondaryText' >{closeTime}</Typography>
            </Grid>
            <Grid item xs={1.714} sx={gridItemStyle}>
                <Typography variant='frameSecondaryText' >{profitLoss} $</Typography>
            </Grid>
        </Grid>
    );
};

export default DealHistoryItem;
