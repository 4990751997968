import { useState } from 'react';
import {
    Grid,
    Typography,
    Stack,
} from "@mui/material";
import sportEventsStyles from 'styles/SportEventsStyles';
import { StarIcon } from 'images';

const SportEventItem = props => {

    const { sportEventItemContainer, stack } = sportEventsStyles();

    const {
        sportEventParticipants,
        isInWatchList,
        gamePeriod,
        eventGoals,
    } = props;

    const [firstParticipantScore, setFirstParticipantScore] = useState(0);
    const [secondParticipantScore, setSecondParticipantScore] = useState(0);

    if (eventGoals) {
        let eventGoalsValues = Object.values(eventGoals);

        if (firstParticipantScore !== eventGoalsValues[0])
            setFirstParticipantScore(eventGoalsValues[0]);

        if (secondParticipantScore !== eventGoalsValues[1])
            setSecondParticipantScore(eventGoalsValues[1]);
    }

    const firstParticipantName = sportEventParticipants[0] !== undefined ? sportEventParticipants[0] : '';
    const secondParticipantName = sportEventParticipants[1] !== undefined ? sportEventParticipants[1] : '';
    const gamePeriodText = gamePeriod !== '' ? gamePeriod : 'Not Started';

    return (

        <Grid container className={sportEventItemContainer}>
            <Grid item xs={4} sx={{ display: 'inline-flex', alignSelf: 'center' }}>
                {isInWatchList ? <StarIcon /> : ''}
                <Typography variant='frameMainText' sx={{ marginLeft: '4px' }}>{gamePeriodText}</Typography>
            </Grid>
            <Grid item xs={7}>
                <Stack className={stack}>
                    <Typography variant='frameMainText'>{firstParticipantName}</Typography>
                    <Typography variant='frameMainText'>{secondParticipantName}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={1}>
                <Stack className={stack}>
                    <Typography variant='frameMainText'>{firstParticipantScore}</Typography>
                    <Typography variant='frameMainText'>{secondParticipantScore}</Typography>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default SportEventItem;
