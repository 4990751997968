import { makeStyles } from "@mui/styles";
import {
    contentBlocksBackgroundColor,
    activeElementColor,
    mainBackgroundColor,
    contentBlocksHeaderColor,
} from 'constants/commonColors';

const sportEventsCalendarStyles = makeStyles({
    sportEventsCalendarContainer: {
        backgroundColor: contentBlocksBackgroundColor,
        borderRadius: '0px 6px 6px 0px',
        marginTop: '8px',
        height: '39.4%',
    },
    sportEventsCalendarContainerMaximized: {
        backgroundColor: contentBlocksBackgroundColor,
        borderRadius: '0px 6px 6px 0px',
        width: '100%',
        height: '100%',
        position: 'absolute',
        bottom: '0',
        zIndex: '100',
        padding: 'inherit',
        left: '0',
    },
    sportEventCalendarItemContainer: {
        backgroundColor: mainBackgroundColor,
        width: '304px',
        height: '48px',
        marginTop: '1px',
    },
    sportEventsCalendarContainerHeading: {
        backgroundColor: contentBlocksHeaderColor,
        color: '#AAAAAA',
        borderRadius: '0px 6px 0px 0px',
        width: '100%',
        height: '38px',
        padding: '7px 0px 7px 24px',
        display: 'inline-flex',
        justifyContent: 'space-between',
    },
    sportEventsCalendarList: {
        overflowY: 'auto',
        height: '21.1vh',
        width: '100%',
        scrollbarColor: `${activeElementColor} ${mainBackgroundColor}`,
        scrollbarWidth: 'thin',
        padding: '0 6px',
        marginTop: '12px',
    },
    sportEventsCalendarListMaximized: {
        overflowY: 'auto',
        height: 'auto',
        width: '100%',
        scrollbarColor: `${activeElementColor} ${mainBackgroundColor}`,
        scrollbarWidth: '1px',
        padding: '0 6px',
    },
    calendarControlsContainer: {
        width: '100%',
        marginTop: '10px',
        padding: '0 6px',
        display: 'inline-flex',
        justifyContent: 'space-between',
    },
    daySwitcher: {
        width: '248px',
        height: '36px',
        border: `1px solid ${mainBackgroundColor}`,
        padding: '6px 32.5px',
        display: 'inline-flex',
        justifyContent: 'space-between',
    },
});

export default sportEventsCalendarStyles;
