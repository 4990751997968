import React, { useRef, useEffect } from 'react';
import PageRoute from 'containers/PageRoute';
import TradingPageContainer from 'containers/TradingPageContainer';
import Presentation from 'containers/Presentation';
import TradingApi from 'api/modules/trading';
import CandleInfoApi from 'api/modules/candleInfo';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const App = () => {

  const sportEventId = 4069266;
  const selectedParticipantId = null;
  const currentUnitPrice = 0;
  const refSportEventId = useRef(sportEventId);
  const refSelectedParticipantId = useRef(selectedParticipantId);
  const refCurrentUnitPrice = useRef(currentUnitPrice);

  useEffect(() => {
    CandleInfoApi.deleteJob();
    TradingApi.resetUserBalance();
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <PageRoute
        container={TradingPageContainer}
        refSportEventId={refSportEventId}
        refSelectedParticipantId={refSelectedParticipantId}
        refCurrentUnitPrice={refCurrentUnitPrice}
      />,
    },
    {
      path: "/presentation",
      element: <Presentation />,
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
};

export default App;
