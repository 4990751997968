import React, { Fragment, useState, useEffect } from 'react';
import CandlestickChartComponent from 'components/CandlestickChartComponent';
import { setupSignalRConnection } from 'helpers/signalRhelper';
import SportEventApi from 'api/modules/sportEvent';
import CandleInfoApi from 'api/modules/candleInfo';
import EventTimeApi from 'api/modules/eventTime';

const CandlestickChartContainer = props => {
    const {
        refSportEventId,
        refSelectedParticipantId,
        refCurrentUnitPrice,
        participantsInfo,
        eventTime,
        eventGoals,
        setEventTime,
        loading,
    } = props;

    const sportEventId = refSportEventId.current;
    const selectedParticipantId = refSelectedParticipantId.current;

    const [japaneseCandles, setJapaneseCandles] = useState([]);
    const [participantAcronyms, setParticipantAcronyms] = useState([]);
    const [leverage, setLeverage] = useState(0);

    const [japaneseCandlesHubConnection, setJapaneseCandlesHubConnection] = useState(null);

    const japaneseCandlesHub = process.env.REACT_APP_JAPANESE_CANDLES_HUB;
    const japaneseCandlesConnectionMethod = `GetJapaneseCandleInfoAsync-${sportEventId}-${selectedParticipantId}`;
    const eventTimeHub = process.env.REACT_APP_EVENT_TIME_HUB;
    const eventTimeConnectionMethod = 'GetEventTimeAsync';

    const receiveNewCandleInfo = response => {
        let mappedCandles = [];

        for (const candle of response) {
            mappedCandles.push({
                open: candle.openPrice,
                high: candle.highPrice,
                low: candle.lowPrice,
                close: candle.closePrice,
                volume: candle.volume,
                timestamp: candle.candleTime
            });
        }

        refCurrentUnitPrice.current = response[response.length - 1].closePrice;

        setJapaneseCandles(mappedCandles);
    };

    useEffect(() => {
        if (selectedParticipantId !== null) {

            getCandleHistory();

            if (japaneseCandlesHubConnection)
                japaneseCandlesHubConnection.stop();

            const connection = setupSignalRConnection(
                japaneseCandlesHub,
                japaneseCandlesConnectionMethod,
                receiveNewCandleInfo
            );
            setJapaneseCandlesHubConnection(connection);
        }
    }, [sportEventId, selectedParticipantId]);

    useEffect(() => {
        const receiveEventTime = response => {
            setEventTime(response.eventTime);
        };

        setupSignalRConnection(eventTimeHub, eventTimeConnectionMethod, receiveEventTime);
    }, [eventTimeHub, eventTimeConnectionMethod, setEventTime]);

    useEffect(() => {
        const getResponse = async () => {
            const acronyms = await SportEventApi.getParticipantAcronyms(sportEventId);
            setParticipantAcronyms(acronyms);
            const leverageValue = await SportEventApi.getLeverage(sportEventId);
            setLeverage(leverageValue.leverage);
        };
        getResponse();
    }, [sportEventId]);

    useEffect(() => {
        const getResponse = async () => {
            await CandleInfoApi.setCandleConfig(15, sportEventId, true);
            await EventTimeApi.startSendingEventTime();
        };
        getResponse();
    }, []);

    const getCandleHistory = async () => {
        const candlesHistory = await CandleInfoApi.getCandleHistory(sportEventId, selectedParticipantId);

        let mappedCandles = [];

        for (const candle of candlesHistory) {
            mappedCandles.push({
                open: candle.openPrice,
                high: candle.highPrice,
                low: candle.lowPrice,
                close: candle.closePrice,
                volume: candle.volume,
                timestamp: candle.candleTime
            });
        }

        setJapaneseCandles(mappedCandles);
    };

    return (
        <Fragment>
            <CandlestickChartComponent
                japaneseCandles={japaneseCandles}
                setJapaneseCandles={setJapaneseCandles}
                participantAcronyms={participantAcronyms}
                participantsInfo={participantsInfo}
                leverage={leverage}
                eventTime={eventTime}
                eventGoals={eventGoals[sportEventId]}
                sportEventId={sportEventId}
                loading={loading}
            />
        </Fragment>
    );
};

export default CandlestickChartContainer;
