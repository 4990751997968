import { Fragment } from "react";
import { Grid, Typography } from "@mui/material";
import navigationBarStyles from 'styles/NavigationBarStyles';
import NavBarLink from './NavBarLink';
import { NavBarIcon, AvatarIcon } from 'images';
import { DepositButton } from '../Buttons/DepositButton';

const NavigationBarComponent = props => {

    const { userBalance } = props;
    const { gridContainer } = navigationBarStyles();

    return (
        <Fragment>
            <Grid container className={gridContainer} >
                <Grid item xs={3}>
                    <Grid container alignContent='center'>
                        <div style={{ width: 'fit-content' }}>
                            <NavBarIcon />
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={6} >
                    <Grid container justifyContent='center' alignContent='center'>
                        <div style={{ width: 'fit-content' }}>
                            <NavBarLink text='HOME' />
                            <NavBarLink text='CATEGORIES' isDropdownList={true} />
                            <NavBarLink text='EVENTS' />
                            <NavBarLink text='ABOUT' />
                            <NavBarLink text='COMPANY' isDropdownList={true} />
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid container justifyContent='right' alignContent='center'>
                        <div style={{ height: 'fit-content', display: 'flex', alignSelf: 'center' }}>
                            <Typography variant='bigCaption' sx={{
                                height: 'fit-content',
                                display: 'flex',
                                alignSelf: 'center',
                            }}>
                                Balance:
                            </Typography>
                            <Typography variant='balanceValueText' sx={{ marginLeft: '6px' }}>{Math.round((userBalance + Number.EPSILON) * 100) / 100}$</Typography>
                        </div>
                        <div style={{ height: 'fit-content', display: 'flex', alignSelf: 'center' }}>
                            <DepositButton />
                        </div>
                        <div style={{ height: 'fit-content', display: 'flex', alignSelf: 'center' }}>
                            <AvatarIcon />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment >
    );
};

export default NavigationBarComponent;
