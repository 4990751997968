import { useState, memo } from 'react';
import {
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import buttonStyles from 'styles/ButtonStyles';
import { nanoid } from 'nanoid';

const CandleTimeControlButtons = props => {

    const { candleTimeValues } = props;

    const [candleTime, setCandleTime] = useState(candleTimeValues[0]);

    const { candleTimeButton, candleTimeButtonsGroup } = buttonStyles();

    const handleCandleTimeChange = (event, newCandleTime) => {
        setCandleTime(newCandleTime);
    };

    const candleTimeControl = {
        value: candleTime,
        onChange: handleCandleTimeChange,
        exclusive: true,
    };

    return (
        <ToggleButtonGroup {...candleTimeControl} className={candleTimeButtonsGroup}>
            {candleTimeValues.map(
                candleTimeValue => {
                    const key = nanoid();
                    return <ToggleButton
                        key={key}
                        value={candleTimeValue}
                        className={candleTimeButton}
                    >
                        <Typography variant="bigCaption">{candleTimeValue} sec</Typography>
                    </ToggleButton>;
                }
            )}
        </ToggleButtonGroup>
    );
};

const candleTimeAreEqual = (prevProps, nextProps) => {

    if (prevProps.candleTimeValues.length === nextProps.candleTimeValues.length) {
        for (let i = 0; i < prevProps.candleTimeValues.length; i++) {
            if (prevProps.candleTimeValues[i] !== nextProps.candleTimeValues[i])
                return true;
        }
        return true;
    }

    return false;
};

export default memo(CandleTimeControlButtons, candleTimeAreEqual);
