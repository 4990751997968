import {
    Link,
    Typography,
} from "@mui/material";
import { ArrowDownIcon } from 'images';

const NavBarLink = props => {
    const { text, isDropdownList } = props;

    return (
        <Link href="#" underline='hover' sx={{ margin: '0 1.68vh 0 1.68vh', display: 'inline-flex' }}>
            <Typography variant='frameCaption'>{text}</Typography>{isDropdownList ? <ArrowDownIcon /> : ''}
        </Link>
    );
};

export default NavBarLink;
