import WrapperContainer from 'containers/WrapperContainer';

const PageRoute = ({ container: Container, refSportEventId, refSelectedParticipantId, refCurrentUnitPrice }) => {
    return (
        <WrapperContainer >
            <Container
                refSportEventId={refSportEventId}
                refSelectedParticipantId={refSelectedParticipantId}
                refCurrentUnitPrice={refCurrentUnitPrice}
            />
        </WrapperContainer>
    );
};

export default PageRoute;
