import SportEventsContainer from 'containers/SportEventsContainer';
import OpenDealsContainer from 'containers/OpenDealsContainer';
import TradingContainer from 'containers/TradingContainer';
import DealHistoryContainer from 'containers/DealHistoryContainer';
import SportEventsCalendarContainer from 'containers/SportEventsCalendarContainer';
import CandlestickChartContainer from 'containers/CandlestickChartContainer';
import { Grid } from '@mui/material';

const TradingPageComponent = props => {
    const {
        refSportEventId,
        refSelectedParticipantId,
        refCurrentUnitPrice,
        participantsInfo,
        openDeal,
        closeDeal,
        openedDeals,
        closedDeals,
        eventTime,
        eventGoals,
        setEventTime,
        selectedDealId,
        setSelectedDealId,
        loading
    } = props;

    return (
        <Grid container columnSpacing={1.5} style={{ display: loading ? 'none' : '', height: '91.1vh' }}>
            <Grid item xs={2.25}>
                <SportEventsContainer
                    refSportEventId={refSportEventId}
                    eventGoals={eventGoals}
                />
                <SportEventsCalendarContainer />
            </Grid>
            <Grid item xs={7.5}>
                <div style={{
                    width: '100%', position: 'relative', height: '100%', position: 'relative', display: 'flex',
                    flexDirection: 'column', justifyContent: 'space-between'
                }}>
                    <CandlestickChartContainer
                        refSportEventId={refSportEventId}
                        refSelectedParticipantId={refSelectedParticipantId}
                        refCurrentUnitPrice={refCurrentUnitPrice}
                        participantsInfo={participantsInfo}
                        eventTime={eventTime}
                        eventGoals={eventGoals}
                        setEventTime={setEventTime}
                        loading={loading}
                    />
                    <DealHistoryContainer deals={closedDeals} />
                </div>
            </Grid>
            <Grid item xs={2.25}>
                <TradingContainer
                    openDeal={openDeal}
                    closeDeal={closeDeal}
                    refSportEventId={refSportEventId}
                    refSelectedParticipantId={refSelectedParticipantId}
                    refCurrentUnitPrice={refCurrentUnitPrice}
                    participantsInfo={participantsInfo}
                    eventTime={eventTime}
                />
                <OpenDealsContainer
                    deals={openedDeals}
                    selectedDealId={selectedDealId}
                    setSelectedDealId={setSelectedDealId}
                />
            </Grid>
        </Grid>
    );
};

export default TradingPageComponent;
