import {
    Button,
    Typography,
} from "@mui/material";
import buttonStyles from "styles/ButtonStyles";

export const DepositButton = () => {
    const { depositButton } = buttonStyles();

    return (
        <Button variant="contained" className={depositButton} sx={{ marginLeft: '20px' }}>
            <Typography variant='buttonText'>DEPOSIT</Typography>
        </Button>
    );
};
