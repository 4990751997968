import { makeStyles } from "@mui/styles";
import {
    mainBackgroundColor,
    activeElementColor,
    contentBlocksHeaderColor,
} from 'constants/commonColors';

const buttonStyles = makeStyles({
    depositButton: {
        background: 'linear-gradient(180deg, #11B904 0%, #30D900 37.11%, #30D900 65.23%, #0EC100 100%)',
        boxShadow: '0px 1px 0px #209100, 0px 4px 10px rgba(0, 0, 0, 0.4)',
        borderRadius: '2px',
        width: '100px',
        height: '38px',
        marginLeft: '20px',
    },
    candleTimeButton: {
        border: '1px solid #FFFFFF',
        borderRadius: '4px',
        width: 'inherit',
        height: '36px',
        margin: '0 6px',
        padding: 0,
        textTransform: 'none',
        '&.Mui-selected': {
            backgroundColor: activeElementColor,
        },
        '&.Mui-selected:hover': {
            backgroundColor: activeElementColor,
        },
        '&.MuiToggleButtonGroup-grouped:not(:first-of-type)':
        {
            'margin-left': '6px',
            'border-left': '1px solid',
            'border-top-left-radius': 'inherit',
            'border-bottom-left-radius': 'inherit',
        },
        '&.MuiToggleButtonGroup-grouped:not(:last-of-type)':
        {
            'border-top-right-radius': 'inherit',
            'border-bottom-right-radius': 'inherit',
        },
        '&>span': {
            width: '100%',
        },
    },
    candleTimeButtonsGroup: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'center',
    },
    teamIconButton: {
        width: '82px',
        height: '60px',
        border: '1px solid #FFFFFF',
        color: 'inherit',
        textTransform: 'unset',
        margin: '0 6px',
        '&.Mui-selected': {
            backgroundColor: activeElementColor,
        },
        '&.Mui-selected:hover': {
            backgroundColor: activeElementColor,
        },
        '&.MuiToggleButtonGroup-grouped:not(:first-of-type)':
        {
            'margin-left': '6px',
            'border-left': '1px solid',
            'border-top-left-radius': 'inherit',
            'border-bottom-left-radius': 'inherit',
        },
        '&.MuiToggleButtonGroup-grouped:not(:last-of-type)':
        {
            'border-top-right-radius': 'inherit',
            'border-bottom-right-radius': 'inherit',
        },
    },
    teamIconButtonsGroup: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'center',
        marginTop: '56px',
    },
    betButton: {
        border: '1px solid #FFFFFF',
        boxSizing: 'border-box',
        borderRadius: '4px',
        width: '100%',
        height: '36px',
        marginTop: '24px',
        color: 'inherit',
        textTransform: 'unset',
        '&:hover': {
            border: '1px solid',
            boxShadow: 'inset 0 0 20px rgb(255 255 255 / 50%), 0 0 10px rgb(255 255 255 / 30%)',
            outlineColor: 'rgba(255, 255, 255, 0)',
            outlineOffset: '15px',
            textShadow: '1px 1px 3px #427388',
        },
    },
    maximizeContainerButton: {
        '&:hover': {
            backgroundColor: 'unset',
        },
    },
    toggleButtonGroup: {
        width: '100%',
        height: '36px',
        padding: '0 6px',
        marginTop: '4px',
    },
    toggleButton: {
        width: '100%',
        fontWeight: 400,
        textTransform: 'none',
        borderRadius: 0,
        borderColor: mainBackgroundColor,
        '&.Mui-selected': {
            backgroundColor: mainBackgroundColor,
            '&:hover': {
                backgroundColor: mainBackgroundColor,
            },
        },
    },
    calendarButton: {
        width: '26px',
        height: '36px',
    },
    searchButton: {
        width: '62px',
        height: '36px',
        backgroundColor: mainBackgroundColor,
        borderRadius: 0,
    },
    sportEventItemButtonGroup: {
        width: '90%',
        height: '36px',
        padding: '0 6px',
    },
    sportEventItemButton: {
        width: '100%',
        fontWeight: 400,
        textTransform: 'none',
        borderRadius: 0,
        padding: 0,
        borderColor: mainBackgroundColor,
        '&.Mui-selected': {
            backgroundColor: contentBlocksHeaderColor,
            '& div': {
                backgroundColor: contentBlocksHeaderColor,
            },
            '&:hover': {
                backgroundColor: contentBlocksHeaderColor,
            },
        },
        '&.MuiButtonBase-root': {
            '& div': {
                '& span': {
                    textAlign: 'justify',
                    lineHeight: '1',
                },
            },
        },
    },
    dealItemButtonGroup: {
        width: '97.5%',
        height: '44px',
    },
    dealItemButton: {
        width: '100%',
        fontWeight: 400,
        textTransform: 'none',
        borderRadius: 0,
        padding: 0,
        borderColor: mainBackgroundColor,
        '&.Mui-selected': {
            backgroundColor: contentBlocksHeaderColor,
            '& div': {
                backgroundColor: contentBlocksHeaderColor,
            },
            '&:hover': {
                backgroundColor: contentBlocksHeaderColor,
            },
        },
    },
    teamIconCard: {
        backgroundColor: 'unset',
        transition: 'unset',
        borderRadius: 'unset',
        boxShadow: 'unset',
        backgroundImage: 'unset',
        margin: '6px',
    },
});

export default buttonStyles;
