import { makeStyles } from "@mui/styles";

const participantInfoStyles = makeStyles({

    participantInfoContainer: {
        display: 'inline-flex',
        alignItems: 'center',
        '& div': {
            margin: '0px 24px',
        }
    },
    card: {
        width: '5vh',
        height: '5vh',
        backgroundColor: 'unset',
        transition: 'unset',
        borderRadius: 'unset',
        boxShadow: 'unset',
        backgroundImage: 'unset',
    }
});

export default participantInfoStyles;
