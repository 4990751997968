import {
    Grid,
    Typography,
    Card,
    CardMedia,
} from "@mui/material";
import openDealsStyles from 'styles/OpenDealsStyles';

const OpenDealItem = props => {

    const {
        openDealsItemContainer,
        openDealParticipantLogo,
    } = openDealsStyles();

    const {
        openedDeal
    } = props;

    const {
        units,
        openPrice,
        openTime,
        profitLoss,
        participantLogoPath
    } = openedDeal;

    const gridItemStyle = { display: 'inline-flex', alignSelf: 'center', justifyContent: 'center' };

    return (
        <Grid container className={openDealsItemContainer} sx={{ width: 'unset', marginRight: '24px' }}>
            <Grid item xs={2} sx={gridItemStyle}>
                <Card className={openDealParticipantLogo}>
                    <CardMedia
                        component="img"
                        image={participantLogoPath}
                        alt={'participantLogoPath'}
                    />
                </Card>
            </Grid>
            <Grid item xs={2.5} sx={gridItemStyle}>
                <Typography variant='frameSecondaryText' >{units}</Typography>
            </Grid>
            <Grid item xs={2.5} sx={gridItemStyle}>
                <Typography variant='frameSecondaryText'>{openPrice}</Typography>
            </Grid>
            <Grid item xs={2.5} sx={gridItemStyle}>
                <Typography variant='frameSecondaryText' >{openTime}</Typography>
            </Grid>
            <Grid item xs={2.5} sx={gridItemStyle}>
                <Typography variant='frameSecondaryText' >{Math.round((profitLoss + Number.EPSILON) * 100) / 100} $</Typography>
            </Grid>
        </Grid>
    );
};

export default OpenDealItem;
