import {
    Button,
    Typography,
} from "@mui/material";
import buttonStyles from 'styles/ButtonStyles';

const BetControlButton = props => {

    const { text, backgroundColor, type, handleSellButtonClick } = props;

    const { betButton } = buttonStyles();

    const buttonStylesOverride = { backgroundColor: backgroundColor, '&:hover': { backgroundColor: backgroundColor } };

    return (
        <Button variant="outlined" className={betButton} sx={buttonStylesOverride} type={type} onClick={handleSellButtonClick}>
            <Typography variant="buttonText">{text}</Typography>
        </Button >
    );
};

export default BetControlButton;
